/**
 * @description Converts PascalCase property names to camelCase for an object or an array of objects
 */

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const propertiesToCamelCase = (o: any | Array<any>) => {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  let newO: any, origKey: string, newKey: string, value: any;

  if (o instanceof Array) {
    return o.map(function (objValue) {
      if (typeof objValue === "object") {
        objValue = propertiesToCamelCase(objValue);
      }
      return objValue;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
        value = o[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = propertiesToCamelCase(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};
