import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Loader } from "components";

/**
 * React.lazy page definitions go here.
 * Please use exact routes
 *
 * Example:
 * <Route exact path="/assignment/:assignment_id" component={IntegrationList} />
 */

const NotFound = React.lazy(() => import("pages/not-found"));
const Overview = React.lazy(() => import("pages/overview"));
const Series = React.lazy(() => import("pages/series"));

const PrivateRoutes = (): React.ReactElement => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route exact path="/">
        <Redirect to="/overview" />
      </Route>
      <Route exact path="/overview" component={Overview} />
      <Route exact path="/series" component={Series} />
      <Route exact path="/series/:series_id" component={Series} />
      <Route component={NotFound} />
    </Switch>
  </Suspense>
);

export default PrivateRoutes;
