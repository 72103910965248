import { ConfigHelper } from "./env";

export const BASE_LB_API_URL =
  process.env.NODE_ENV === "production"
    ? ConfigHelper.getConfig("lb_api_host")
    : "https://lbapi.dev.lightning-bolt.com";

export const LB_FALLBACK_LOGIN_URL =
  process.env.NODE_ENV === "production"
    ? ConfigHelper.getConfig("lb_fallback_login_url")
    : "https://s2.dev.lightning-bolt.com";

export const CLIENT_ID =
  process.env.NODE_ENV === "production" ? ConfigHelper.getConfig("client_id") : "1bc4e40b-0373-42a8-bfed-979f10b0743a";

export const API_DEFAULT_ERROR_MESSAGE =
  "An error has occurred. Try refreshing the page. If this error continues, please contact support.";
