import React from "react";
import InputMask from "react-input-mask";
import classnames from "classnames";
import { FieldProps, getIn } from "formik";

import ErrorMessage from "../meta/ErrorMessage";
import Label from "../meta/Label";

import styles from "./styles.module.scss";

interface Props extends FieldProps<string> {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  rows?: number;
  size?: "small" | "medium" | string;
  formatter?: null | ((value: string) => boolean);
  mask?: string | Array<string | RegExp>;
  maskPlaceholder?: string;
  alwaysShowMask?: boolean;
  [key: string]: unknown;
}

const RenderTextField = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  label = "",
  placeholder = "",
  disabled = false,
  multiple = false,
  rows = 2,
  size = "medium",
  formatter = null,
  mask = "",
  maskPlaceholder = "",
  alwaysShowMask = true,
  ...rest
}: Props): React.ReactElement => {
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  const rootClassNames = classnames({
    [styles.root]: true,
    [styles.disabled]: disabled,
  });

  const inputClassNames = classnames({
    [styles.input]: true,
    [styles.small]: size === "small",
    [styles.medium]: size === "medium",
    [styles.error]: touch && !!error,
  });

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (formatter) formatter(e.target.value) && onChange(e);
    else onChange(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onBlur(e);
  };

  const props = {
    className: inputClassNames,
    id: name,
    name,
    value,
    onChange: handleChange,
    onBlur: handleBlur,
    placeholder,
    disabled,
    ...rest,
  };

  return (
    <div className={rootClassNames}>
      {label && <Label text={label} />}
      {mask ? (
        <InputMask mask={mask} maskPlaceholder={maskPlaceholder} alwaysShowMask={alwaysShowMask} {...props} />
      ) : (
        <>{multiple ? <textarea style={{ resize: "none" }} rows={rows} {...props} /> : <input {...props} />}</>
      )}
      {touch && !!error && !disabled && <ErrorMessage text={error} />}
    </div>
  );
};

export default RenderTextField;
