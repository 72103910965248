import React from "react";

import { State } from "./provider";

export interface Context {
  state: State;
}

export default React.createContext<Context>({
  state: {
    user: {
      user_id: undefined,
      user_name: undefined,
      emp_id: undefined,
      customer_id: undefined,
      customer_name: undefined,
      is_admin: undefined,
      is_super_admin: undefined,
      user_access_role_id: undefined,
    },
    isAuthenticated: false,
    isLoading: true,
    access_token: undefined,
    expires_in: undefined,
    refresh_token: undefined,
  },
});
