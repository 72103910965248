import { DateTime } from "luxon";

/**
 * @description Returns the current system date in yyyy-MM-dd ISO8061 format
 */
export const getCurrentIsoDate = (): string => DateTime.now().toISODate();

/**
 * @description Returns the current system date in MM/dd/yyyy format
 */
export const getCurrentEnUsDate = (): string => DateTime.now().toFormat("MM/dd/yyyy");

export const enUSDateToISO8061 = (dateString: string): string =>
  DateTime.fromFormat(dateString, "MM/dd/yyyy").toFormat("yyyy-MM-dd");

export const ISO8061ToEnUS = (dateString: string): string => DateTime.fromISO(dateString).toFormat("MM/dd/yyyy");

/**
 * @description Returns the passed date in 2021-10-01T00:00:00.000Z ISO format as a string
 */
export const dateTimeToIsoString = (dateString: string): string => new Date(dateString).toISOString();

/**
 * @description Returns the date of tomorrow system date in yyyy-MM-dd ISO8061 format
 */
export const getTomorrowIsoDate = (): string => DateTime.now().plus({ days: 1 }).toISODate();

/**
 * @description Returns the passed date in yyyy-MM-dd format as a string
 */
export const ISO8061ToEnUSDate = (dateString: string): string => DateTime.fromISO(dateString).toFormat("yyyy-MM-dd");

/**
 * @description Returns the passed date in MMM dd, yyyy format
 */
export const ISO8061ToFullDate = (dateString: string): string => DateTime.fromISO(dateString).toFormat("MMM dd, yyyy");

/**
 * @description Returns the new date after n-days in yyyy-MM-dd ISO8061 format
 */
export const addDaysToDate = (dateString: string, days: number): string =>
  DateTime.fromISO(dateString).plus({ days: days }).toFormat("yyyy-MM-dd");
