import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Loader } from "components";

const NotAuthorized = React.lazy(() => import("pages/not-authorized"));
const NotFound = React.lazy(() => import("pages/not-found"));

/** Note: Any route which requires the user to be authenticated
should render the NotAuthorized component, everything else should
fall through to the NotFound component
Please make use of exact routes
Example:
<Route exact path="/series/:series" component={NotAuthorized} />
**/

const PublicRoutes = (): React.ReactElement => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route exact path="/series" component={NotAuthorized} />
      <Route exact path="/overview" component={NotAuthorized} />
      <Route exact path="/series/:series_id" component={NotAuthorized} />
      <Route component={NotFound} />
    </Switch>
  </Suspense>
);

export default PublicRoutes;
