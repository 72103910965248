import React from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

import { post } from "@pslightningbolt/retriever";

import { BASE_LB_API_URL, clearAuthData, CLIENT_ID } from "../../utils";

import Context from "./context";

const HOUR_IN_MILLISECONDS = 3600 * 1000;

interface TokenResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

interface User {
  user_id?: number;
  user_name?: string;
  emp_id?: number;
  customer_id?: number;
  customer_name?: string;
  is_admin?: boolean;
  is_super_admin?: boolean;
  user_access_role_id?: number | string;
}

export interface Props {
  children: React.ReactNode;
}

export interface State {
  user: User;
  isAuthenticated: boolean;
  isLoading: boolean;
  access_token?: string;
  expires_in?: number;
  refresh_token?: string;
}

export default function AuthProvider({ children }: Props): React.ReactElement {
  const location = useLocation();
  const token = location.search.split("?token=")[1];

  const cookies = new Cookies();

  const [state, setState] = React.useState<State>({
    user: {
      user_id: undefined,
      user_name: undefined,
      emp_id: undefined,
      customer_id: undefined,
      customer_name: undefined,
      is_admin: undefined,
      is_super_admin: undefined,
      user_access_role_id: undefined,
    },
    isAuthenticated: false,
    isLoading: true,
    access_token: undefined,
    expires_in: undefined,
    refresh_token: undefined,
  });

  const handleSetData = (status: number, data: TokenResponse | null) => {
    switch (status) {
      case 401:
      case 400:
        clearAuthData();
        break;
      case 200:
        if (data) {
          cookies.set("LB_TKN", data.refresh_token, { path: "/" });
          setState((prevState) => ({
            ...prevState,
            isAuthenticated: true,
            isLoading: false,
            access_token: data.access_token,
            expires_in: data.expires_in,
            refresh_token: data.refresh_token,
          }));
        }
        break;
      default:
        setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  React.useEffect(() => {
    const refreshToken = cookies.get("LB_TKN");
    //Login if we do not have the token
    if (refreshToken === "undefined" || refreshToken === undefined) {
      (async () => {
        const { data, status } = await post<TokenResponse>(
          `${BASE_LB_API_URL}/token`,
          `grant_type=password&username=${token}&password=&client_id=${CLIENT_ID}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        );
        handleSetData(status, data);
      })();
    } else {
      //Get refresh token if we already have the refresh token cookie
      (async () => {
        const { data, status } = await post<TokenResponse>(
          `${BASE_LB_API_URL}/token`,
          `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${CLIENT_ID}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        );
        handleSetData(status, data);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get the refresh token on before it expires
  React.useEffect(() => {
    const refreshTokenInterval = state?.expires_in ? (state.expires_in - 600) * 1000 : HOUR_IN_MILLISECONDS - 120000;
    const interval = setInterval(async () => {
      const refreshToken = cookies.get("LB_TKN");

      await (async () => {
        const { data, status } = await post<TokenResponse>(
          `${BASE_LB_API_URL}/token`,
          `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${CLIENT_ID}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        );
        handleSetData(status, data);
      })();
    }, refreshTokenInterval);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Context.Provider
      value={{
        state,
      }}
    >
      {children}
    </Context.Provider>
  );
}
