import { OptionTypeBase, StylesConfig, Theme } from "react-select";

const COLOR_LIGHT_GREY = "#D9D9D9";

export const getSelectCustomTheme = () => (theme: Theme) => ({
  ...theme,
  borderRadius: 2,
  colors: {
    ...theme.colors,
    primary: "rgb(66, 179, 227, 0.7)",
    primary25: "#F0F4FF",
    primary50: "#DCE7FF",
  },
});

export const getSelectCustomStyles = ({
  value,
  disabled,
  touch,
  error,
  size,
  maxShownChipsCount,
}: {
  value: null | OptionTypeBase | OptionTypeBase[];
  disabled: boolean;
  touch: boolean;
  error: string;
  size: string;
  maxShownChipsCount: number;
}): StylesConfig<OptionTypeBase, boolean> => ({
  control: (provided, state) => ({
    ...provided,
    ...(size === "small"
      ? {
          padding: "0 0 0 9px",
          minHeight: 32,
          height: 32,
        }
      : {
          padding: "0 0 0 11px",
        }),
    color: "rgba(0, 0, 0, 0.85)",
    backgroundColor: "#FFFFFF",
    lineHeight: 1.6,
    transition: "all 0.3s",
    cursor: state.isFocused ? "text" : "pointer",
    border: `1px solid ${COLOR_LIGHT_GREY}`,
    borderRadius: "2px",
    borderColor: disabled
      ? `${COLOR_LIGHT_GREY} !important`
      : touch && error
      ? "#E26868 !important"
      : state.isFocused
      ? "#40A9FF !important"
      : `${COLOR_LIGHT_GREY}`,
    boxShadow:
      state.isFocused && !disabled
        ? touch && error
          ? "0 0 0 2px rgba(226, 104, 104, 0.2)"
          : "0 0 0 2px rgba(24, 144, 255, 0.2)"
        : "none",
    "&:hover": {
      borderColor: touch && error ? "#E26868" : "#42B3E3",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    ...(size === "small"
      ? {
          position: "initial",
        }
      : {}),
    padding: "0 11px 0 0",
    "&:after": {
      content:
        Array.isArray(value) && value.length > maxShownChipsCount ? `"+ ${value.length - maxShownChipsCount}"` : '""',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.85)",
    opacity: "0.35 !important",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
  group: (provided, state) => ({
    ...provided,
    ...(!state.label ? { paddingTop: 0 } : {}),
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    ...(!state.children ? { marginBottom: 0 } : {}),
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    cursor: state.data.isDisabled ? "not-allowed" : "pointer",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: 11,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    ...(size === "small"
      ? {
          height: "32px",
        }
      : {}),
  }),
});
