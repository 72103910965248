import React from "react";

import Styles from "./styles.module.scss";

/**
 * @description ActionsToast component
 * @param {string} message The message to be displayed.
 * @param {ReactNode} icon The icon to display before the message.
 * @param {ReactNode} actions The buttons to trigger the needed actions.
 */

interface Props {
  message: string;
  actions?: React.ReactNode;
  icon?: React.ReactNode;
}

const ActionsToast: React.FC<Props> = ({ message, icon, actions }: Props): JSX.Element => {
  return (
    <div className="mud-guard align-items-center" id={Styles.styleOverride}>
      <div className="mud-guard-message">
        {icon}
        {message}
      </div>
      {actions && <div className="mud-guard-controls d-flex">{actions}</div>}
    </div>
  );
};

export default ActionsToast;
