import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary, Loader } from "components";
import { PrivateRoutes, PublicRoutes } from "routes";
import { reportWebVitals } from "utils";

import "@telmediq/ps-styleguide";
import "@telmediq/mud-guard";

import { AuthConsumer, AuthProvider } from "./store/auth";

import "nprogress/nprogress.css";
import "./scss/index.scss";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <ErrorBoundary>
        <AuthProvider>
          <AuthConsumer>
            {({ state }) => (
              <>
                <ToastContainer />
                {state.isLoading ? <Loader /> : state.isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />}
              </>
            )}
          </AuthConsumer>
        </AuthProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
