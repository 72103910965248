import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
  text: string;
  className?: string;
}

const ErrorMessage = ({ text, className = "" }: Props): React.ReactElement => {
  const classNames = classnames(className, {
    [styles.error]: true,
  });

  return <div className={classNames}>{text}</div>;
};

export default ErrorMessage;
