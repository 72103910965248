interface ConfigSet {
  integrations_api_host: string | undefined;
  lb_api_host: string | undefined;
  lb_fallback_login_url: string | undefined;
  client_id: string | undefined;
}

interface Config extends ConfigSet {
  getConfig: (urlName: keyof ConfigSet) => string | undefined;
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const env = (window as any).env ?? {};

export const ConfigHelper: Config = {
  integrations_api_host: env.INTEGRATIONS_API_HOST,
  lb_api_host: env.LB_API_HOST,
  lb_fallback_login_url: env.LB_FALLBACK_LOGIN_URL,
  client_id: env.LB_CLIENT_ID,

  getConfig(urlName) {
    return this[urlName];
  },
};
