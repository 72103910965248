import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  color?: "default" | "primary" | "secondary";
  variant?: "filled" | "outlined" | "text";
  size?: "small" | "medium" | "large";
  type?: "button" | "submit" | "reset";
  onClick?: (e: React.MouseEvent<HTMLElement>) => unknown;
  children: React.ReactNode;
  [key: string]: unknown;
}

const Button = ({
  className = "",
  loading = false,
  disabled = false,
  fullWidth = false,
  color = "default",
  variant = "filled",
  size = "medium",
  type = "button",
  onClick = () => null,
  children,
  ...rest
}: Props): React.ReactElement => {
  const rootClassNames = classnames(className, {
    [styles.root]: true,
    [styles.default]: color === "default",
    [styles.primary]: color === "primary",
    [styles.secondary]: color === "secondary",
    [styles.filled]: variant === "filled",
    [styles.outlined]: variant === "outlined",
    [styles.text]: variant === "text",
    [styles.small]: size === "small",
    [styles.medium]: size === "medium",
    [styles.large]: size === "large",
    [styles.fullWidth]: fullWidth,
    [styles.loading]: loading,
    [styles.disabled]: disabled,
  });

  return (
    <button className={rootClassNames} onClick={onClick} disabled={disabled} type={type} {...rest}>
      {loading ? (
        <>
          <div className={styles.spinner} /> {children}
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
