import React from "react";
import Select, {
  ActionMeta,
  components,
  MultiValueProps,
  OptionsType,
  OptionTypeBase,
  Props as SelectProps,
} from "react-select";
import { Option as FilterOption } from "react-select/src/filters";
import classnames from "classnames";
import { getSelectCustomStyles, getSelectCustomTheme } from "components/helpers/styles.helper";
import { FieldProps, getIn, useField } from "formik";

import ErrorMessage from "../meta/ErrorMessage";
import Label from "../meta/Label";

import styles from "./styles.module.scss";

interface Props extends SelectProps<OptionTypeBase, boolean>, FieldProps<null | string | string[]> {
  options: OptionTypeBase[];
  label?: string;
  placeholder?: string;
  size?: "small" | "medium" | string;
  disabled?: boolean;
  clearable?: boolean;
  loading?: boolean;
  searchable?: boolean;
  multiple?: boolean;
  disableManySelect?: boolean;
  getOptionLabel?: (option: OptionTypeBase) => string;
  getOptionValue?: (option: OptionTypeBase) => string;
  onSelect?: (option?: OptionTypeBase) => void;
  isGroupedData?: boolean;
}

const MAX_SHOWN_CHIPS_COUNT = 1;

const mapOptionsValues = (options: OptionTypeBase[], getOptionValue: (option: OptionTypeBase) => string) => {
  return options.flatMap((o) => getOptionValue(o) ?? []);
};

const getTransformedValue = (
  options: OptionTypeBase[],
  formikValue: null | string | string[],
  multiple: boolean,
  getOptionValue: (option: OptionTypeBase) => string,
  isGroupedData: boolean
): null | OptionTypeBase | OptionTypeBase[] => {
  if (multiple) {
    return options.filter((o) => Array.isArray(formikValue) && formikValue.includes(getOptionValue(o)));
  }
  if (isGroupedData) {
    const vals: OptionTypeBase[] = [];

    options.forEach((item) => {
      if (item.options) {
        vals.push(...item.options);
      }
    });

    return vals.find((o) => formikValue === getOptionValue(o)) ?? null;
  }

  return options.find((o) => formikValue === getOptionValue(o)) ?? null;
};

const filterOption = (option: FilterOption, input: string): boolean => {
  return option.label?.toLowerCase().includes(input.toLowerCase()) ?? false;
};

const RenderSelectField = ({
  field: { name, value },
  form: { errors, touched, setFieldValue, setFieldTouched },
  label = "",
  placeholder = "",
  size = "medium",
  disabled = false,
  clearable = true,
  loading = false,
  searchable = true,
  multiple = false,
  getOptionLabel = (option) => option.label,
  getOptionValue = (option) => option.value,
  options,
  onSelect,
  isGroupedData = false,
  ...rest
}: Props): JSX.Element => {
  const transformedValue = getTransformedValue(options, value, multiple, getOptionValue, isGroupedData);
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  const rootClassNames = classnames({
    [styles.root]: true,
    [styles.disabled]: disabled,
  });

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const handleChange = (
    option: OptionTypeBase | OptionsType<OptionTypeBase> | null,
    meta: ActionMeta<OptionTypeBase>
  ) => {
    if (!option || meta.action === "clear") {
      onSelect && onSelect(undefined);
      return setFieldValue(name, multiple ? [] : null);
    }

    onSelect && onSelect(option);

    setFieldValue(name, Array.isArray(option) ? option.map((o) => o.value) : (option as OptionTypeBase).value);
  };

  const MultiValueContainer = React.useCallback(
    ({ children, data, ...rest }: MultiValueProps<OptionTypeBase>) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [{ value }] = useField(name);
      const position = data && mapOptionsValues(value, getOptionValue).indexOf(getOptionValue(data));

      return position >= MAX_SHOWN_CHIPS_COUNT ? null : (
        <components.MultiValueContainer data={data} {...rest}>
          {children}
        </components.MultiValueContainer>
      );
    },
    [getOptionValue, name]
  );

  return (
    <div className={rootClassNames}>
      {label && <Label text={label} />}
      <Select
        theme={getSelectCustomTheme()}
        styles={getSelectCustomStyles({
          value: transformedValue,
          disabled,
          touch,
          error,
          size,
          maxShownChipsCount: MAX_SHOWN_CHIPS_COUNT,
        })}
        components={{ MultiValueContainer }}
        value={transformedValue}
        onBlur={handleBlur}
        onChange={handleChange}
        filterOption={filterOption}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        placeholder={placeholder}
        options={options}
        isDisabled={disabled}
        isClearable={clearable}
        isLoading={loading}
        isSearchable={searchable}
        closeMenuOnSelect={!multiple}
        isMulti={multiple}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        {...rest}
      />
      {touch && !!error && !disabled && <ErrorMessage text={error} />}
    </div>
  );
};

export default RenderSelectField;
