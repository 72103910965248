import React from "react";
import NProgress from "nprogress";

export default function Loader(): null | React.ReactElement {
  React.useEffect(() => {
    NProgress.configure({ showSpinner: false });
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return null;
}
