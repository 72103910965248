import { toast as messageToast } from "react-toastify";

export enum TOAST_TYPE {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  DEFAULT = "default",
  DARK = "dark",
}

export const toast = (message: string, type?: TOAST_TYPE) => {
  return messageToast(message, { type: type ?? TOAST_TYPE.DEFAULT });
};
